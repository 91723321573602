<!--== Services Area Start ==-->
<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Our Services</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                    <p>Al Bathaa is proud to offer numerous transportation options which are perfect for private shuttle transfers as well as group shuttle transfers. Our impeccable service, high-quality drivers, and a kid-friendly staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter vehicles.
Our Services include but are not limited to the following:
 </p>
                   
                </div>
            </div>
            <!-- Section Title End -->
        </div>

       
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-graduation-cap"></i>
                    <a routerLink="/school_transport" routerLinkActive="active"><h3>School Transportation</h3></a>
                    <p>School buses are fitted with CCTV inside for the driver to monitor the kids, in addition to reverse view CCTV.</p>
                </div>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-industry"></i>
                    <a routerLink="/industrial_transport" routerLinkActive="active"><h3>Industrial Transportation</h3></a>
                    <p>Albathaa provides the Bus transportation for Industrial employees based on the agreed contracts.</p>
                </div>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-building"></i>
                    <a routerLink="/hotel_transport" routerLinkActive="active"><h3>Hotels Transportation</h3></a>
                    <p>Albathaa provides the Bus transportation for Hotels for their guest based on the agreed contracts.</p>
                </div>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-users"></i>
                    <a routerLink="/corporate_transport" routerLinkActive="active"><h3>Corporate Transportation</h3></a>
                    <p>Albathaa provides the Bus transportation for Corporate sector based on the agreed contracts.</p>
                </div>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-bus"></i>
                    <a routerLink="/on_rent_transport" routerLinkActive="active"><h3>Buses on Rent</h3></a>
                    <p>No matter what requirements you may have, we can offer you the best solution to meet your needs. </p>
                </div>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-4 text-center">
                <div class="service-item">
                    <i class="fa fa-handshake-o"></i>
                    <a routerLink="/lease_transport" routerLinkActive="active"><h3>Long Term & Short Term Lease</h3></a>
                    <p>Albathaa specializes in provides leasing buses to individuals, companies and corporates across the State of Qatar.</p>
                </div>
            </div>
            <!-- Single Service End -->
        </div>
        <!-- Service Content End -->
    </div>
</section>
<!--== Services Area End ==-->