<!--== About Us Area Start ==-->
  <section id="about-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>About us</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                    <p>Our experienced team is focused on exceeding our clients' expectations.</p>
                </div>
            </div>
            <!-- Section Title End -->
        </div>

        <div class="row">
            <!-- About Content Start -->
            <div class="col-lg-6">
                <div class="display-table">
                    <div class="display-table-cell">
                        <div class="about-content">
                        <p>
                        Al Bathaa proudly offers luxury transport services in Qatar. We are one of Qatar’s most experienced private busses and school bus service provider and have been providing our exceptional transportation services for the last 30 years. Whatever your transportation needs, you've come to the right place. We are committed to delivering the best in class, taste, and distinction, all while making sure to take special care of you and leave you safe and satisfied. We strive to deliver and outstanding experience with our customized service.</p>
<p>Our clientele includes tours and charters, school groups, sports team, religious and recreational groups, corporate executives, tour companies, inbound international tourists and conventions. We offer a variety of services that are designed to meet your every need. Providing safe equipment with outstanding customer service and reliability is our difference!
We can arrange for vehicles to suit your needs. Our excellent team includes experienced and licensed drivers and chauffeurs who pride themselves in Qatari hospitality. In addition our drivers come from a diverse and multicultural background and boast fluency in English, Arabic, Urdu, Hindi, Nepali and Tagalog.</p>
<p>Our company believes in diligence and safety, that is why our fleet of vehicles are regularly maintained under strict maintenance protocol and inspected daily. A clean and modern fleet awaits you. 
</p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- About Content End -->

            
            <!-- About Video Start -->
            <div class="col-lg-6">
                <div class="about-video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/idnm2o6IUwA?start=16" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <!-- About Video End -->
            <div *ngIf="hasRoute('about')">
                <div class="col-md-12 about-content">
                    <p> We can arrange for vehicles to suit your needs. Our excellent team includes experienced and licensed drivers who pride themselves in Qatari hospitality. In addition our drivers come from a diverse and multicultural background and boast fluency in English, Arabic, Urdu, Hindi, Nepali and Tagalog.</p>
                    <p>Our company believes in diligence and safety, that is why our fleet of vehicles are regularly maintained under strict maintenance protocol and inspected daily. A clean and modern fleet awaits you. </p>
                    <div class="about-btn">
                        <a routerLink="/contact">Get A Quote</a>
                    </div>
                </div>
            </div>   
        </div>
        <div *ngIf="hasRoute('about')">
            <app-highlights></app-highlights>
        </div>
    </div>
</section> 
<!--== About Us Area End ==-->
<!-- First view -->
