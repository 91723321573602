import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { BookingComponent } from './booking/booking.component';
import { PricingComponent } from './pricing/pricing.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { BlogsComponent } from './blogs/blogs.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { ClientComponent } from './client/client.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { SchoolTransportComponent } from './school-transport/school-transport.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { PartnersComponent } from './partners/partners.component';
import { IndustrialTransportComponent } from './industrial-transport/industrial-transport.component';
import { HotelTransportComponent } from './hotel-transport/hotel-transport.component';
import { CorporateTransportComponent } from './corporate-transport/corporate-transport.component';
import { OnRentTransportComponent } from './on-rent-transport/on-rent-transport.component';
import { LeaseTransportComponent } from './lease-transport/lease-transport.component';
import { CareerComponent } from './career/career.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    BookingComponent,
    PricingComponent,
    TestimonialsComponent,
    BlogsComponent,
    FooterComponent,
    ContactComponent,
    ClientComponent,
    HighlightsComponent,
    SchoolTransportComponent,
    SuppliersComponent,
    PartnersComponent,
    IndustrialTransportComponent,
    HotelTransportComponent,
    CorporateTransportComponent,
    OnRentTransportComponent,
    LeaseTransportComponent,
    CareerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
