 <!--== Page Title Area Start ==-->
 <section id="page-title-area" class="section-padding overlay">
    <div class="container">
        <div class="row">
            <!-- Page Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Our Partners</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Page Title End -->
        </div>
    </div>
</section>
<!--== Page Title Area End ==-->
<!--== Client Page Area Start ==-->
<div class="client-page section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>Al Bathaa has proudly been offering luxury transport services in Qatar for over 30 years. We are one of Qatar's most experienced private busses and school bus service provider.
It is our mission to provide you with the best service in the industry, from the best vehicles to the best chauffeurs with always your safety and satisfaction as our top priority.
We share elite partnerships with these esteemed companies:
</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/img/partners/partner-1.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/partners/partner-2.png" alt="client-logo" />
             </div>
            <div class="col-md-3">
                <img src="assets/img/partners/partner-3.png" alt="client-logo" />
            </div>       
             <div class="col-md-3">
                <img src="assets/img/partners/partner-4.png" alt="client-logo" />
             </div>
        </div>
    </div>
</div>
<!--== Client Page Area End ==-->