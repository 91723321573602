<!--== Choose Car Area Start ==-->
<section id="choose-car" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Our Vehicles</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                    <p>Our range of buses allows us to accommodate most.</p>
                </div>
            </div>
            <!-- Section Title End -->
        </div>

        <div class="row">
            <!-- Choose Area Content Start -->
            <div class="col-lg-12">
                <div class="choose-content-wrap">
                    <!-- Popular Cars Tab Start -->
                    <div class="tab-pane fade show active" id="popular_cars" role="tabpanel" aria-labelledby="home-tab">
                        <!-- Popular Cars Content Wrapper Start -->
                        <div class="popular-cars-wrap">
                            <!-- Filtering Menu -->
							<div class="popucar-menu text-center">
								<a href="/#" data-filter="*" class="active">All</a>
								<a href="/#" data-filter=".bus">Buses</a>
								<a href="/#" data-filter=".truck">Trucks</a>
							</div>
							<!-- Filtering Menu -->
                            <!-- PopularCars Tab Content Start -->
							<div class="row popular-car-gird">
								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 bus">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/bus-1.png">
											  <img src="assets/img/buses/bus-1.png" alt="JSOFT">
										   </a>
										</div>
										<div class="p-car-content">
											<h3>
												<a href="#">Mini Bus</a>
											</h3>
											<h5>15 Seats Bus</h5>
											<div class="p-car-feature">
												<a href="#">2016</a>
												<a href="#">2017</a>
												<a href="#">2018</a>
												<a href="#">2019</a>
												<a href="#">2020</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 bus">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/bus-1.png">
											  <img src="assets/img/buses/coaster.png" alt="JSOFT">
										   </a>
										</div>
										<div class="p-car-content">
											<h3>
												<a href="#">Coaster</a>
											</h3>
											<h5>26-30 Seats Bus</h5>
											<div class="p-car-feature">
												<a href="#">manual</a>
												<a href="#">AIR CONDITION</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 bus">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/bus-2.png">
											  <img src="assets/img/buses/bus-2.png" alt="JSOFT">
										   </a>
										</div>
										<div class="p-car-content">
											<h3>
												<a href="#">Medium Bus</a>
											</h3>
											<h5>40 Seats Bus</h5>
											<div class="p-car-feature">
												<a href="#">manual</a>
												<a href="#">AIR CONDITION</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 bus">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/bus-3.png">
											  <img src="assets/img/buses/bus-3.png" alt="JSOFT">
										   </a>
										</div>
										<div class="p-car-content">
											<h3>
												<a href="#">Large Bus</a>
											</h3>
											<h5>60-65 Seats Bus</h5>
											<div class="p-car-feature">
												<a href="#">manual</a>
												<a href="#">AIR CONDITION</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start 
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-1.png">
											  <img src="assets/img/buses/truck-1.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Industrial Truck</a>
											</h3>
										</div>
									</div>
								</div>-->
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-2.png">
											  <img src="assets/img/buses/truck-2.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Trailer</a>
											</h3>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->

								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-3.png">
											  <img src="assets/img/buses/truck-3.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Truck with tail</a>
											</h3>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->
								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-4.png">
											  <img src="assets/img/buses/truck-4.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Lorry Truck</a>
											</h3>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->
								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-7.png">
											  <img src="assets/img/buses/truck-7.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Normal Truck</a>
											</h3>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->
								<!-- Single Popular Car Start -->
								<div class="col-lg-4 col-md-6 truck">
									<div class="single-popular-car">
										<div class="p-car-thumbnails">
											<a class="car-hover" href="assets/img/buses/truck-8.png">
											  <img src="assets/img/buses/truck-8.png" alt="JSOFT">
										   </a>
										</div>

										<div class="p-car-content">
											<h3>
												<a href="#">Truck with Equipment</a>
											</h3>
										</div>
									</div>
								</div>
								<!-- Single Popular Car End -->
							</div>
						<!-- Popular Cars Content Wrapper End -->
                        </div>
                        <!-- Popular Cars Tab End -->
                    </div>
                    <!-- Choose Area Tab content -->
                </div>
            </div>
            <!-- Choose Area Content End -->
        </div>
    </div>
</section>
<!--== Choose Car Area End ==-->