<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Industrial Transportation</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p>Al Bathaa also provides an efficient and reliable transportation alternative to Industrial employees. No matter the size of company’s workforce, our flexible pricing and vehicle size can accommodate any company size.  Our transportation technology capabilities can help your company optimize the best route.</p>
                <p>We also possess one of the best safety records which make us the best choice for Industrial employee travel. Our entire fleet of buses are fitted with CCTV cameras for the monitoring and safety of all occupants.</p>
                <p>We strive to deliver the highest quality of service. We transform the typical daily commute into a relaxing and luxurious extravaganza. Our impeccable service, high-quality drivers and highly professional staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
            </div>
            <!-- Single Service End -->       
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/industry.png"/>
            </div>
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->