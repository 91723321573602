<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>School Transportation</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p> Our impeccable service, high-quality drivers, and a kid-friendly staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
                <p>We also possess one of the best safety records which make us the best choice for student travel. Our entire fleet of buses are fitted with CCTV cameras for the monitoring and safety of all occupants. Moreover our state of the art vehicles are equipped with reverse view CCTV camera and an electronic STOP sign on driver’s side.</p>
                <p>The safety and comfort of our passengers is paramount and so all our vehicles are fitted with First Aid kits, water coolers, multiple emergency exits and auto engine fire suppression system. All seats are equipped with seat belts and individual AC controls.</p>
                <p>We strive to deliver the highest quality of service. We transform the typical daily commute into a relaxing and luxurious extravaganza. Additionally by reducing the congestion of private cars on the road we have fewer accidents and safer school zone areas. This is Al Bathaa’s way of making a social and environmental contribution to our planet.</p>
                <b>For Inquiry</b> +974 44373725; info@albathaaqatar.com
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/school.png"/>
                <img src="assets/img/services/school1.png"/>
            </div>
            
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->