import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-transport',
  templateUrl: './school-transport.component.html',
  styleUrls: ['./school-transport.component.css']
})
export class SchoolTransportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
