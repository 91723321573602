<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Corporate Transportation</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p>Trustworthy, comfortable, safe, first-class ground transportation is essential to the success of meetings and corporate events. We understand how much planning goes into meetings, outings, training sessions, promotional events, conferences, and more without worrying about charter bus rentals.</p>
                <p>Whether you need to visit clients, get to urgent meetings, have visiting clients, or are simply looking to travel from one place to another, we offer fairly-priced and easy door-to-door luxury transportation services. We provide customized solutions to match all your executive and corporate needs. We can handle small group outings to large conventions, offering a variety of corporate charter bus options.</p>
                <p>Al Bathaa also provides an efficient and reliable transportation alternative to employees. No matter the size of company’s workforce, our flexible pricing and vehicle size can accommodate any company size.  Our transportation technology capabilities can help your company optimize the best route, and even offer door to door service.</p>
                <p>The safety and comfort of our passengers is paramount and so all our vehicles are fitted with First Aid kits, water coolers, multiple emergency exits and auto engine fire suppression system. All seats are equipped with seat belts and individual AC controls. </p>
                <p>Our impeccable service, high-quality drivers and highly professional staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/corporate.png"/>
            </div>
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->