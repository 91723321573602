<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Buses On Rent</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p> Wherever you need to go, whatever you need to do, and whomever you need to do it with, we will get you there comfortably, safely, and in style. Whatever your needs, we have a vehicle to suit them.</p>
                <p>Our impeccable service, high-quality drivers and highly professional staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
                <p>We possess one of the best safety records which make us the best choice for travel. Our entire fleet of buses are fitted with CCTV cameras for the monitoring and safety of all occupants. The safety and comfort of our passengers is paramount and so all our vehicles are fitted with First Aid kits, water coolers, multiple emergency exits and auto engine fire suppression system. All seats are equipped with seat belts and individual AC controls. </p>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/rent.png"/>
            </div>
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->