<div class="home-page">
<!--== Slider Area Start ==-->
  <section id="slider-area">
    <!--== slide Item One ==-->
    <div class="single-slide-item">
        <div class="container">
            <div class="row">
                <div class="col-lg-7"></div>
                <div class="col-lg-5  text-left">
                    <div class="display-table">
                        <div class="display-table-cell">
                            <div class="slider-right-text">
                                <h1>BOOK A Bus TODAY!</h1>
                                <p>Best Buses, Best Services, Best Safety</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--== slide Item One ==-->
</section>
<!--== Slider Area End ==-->
<app-about></app-about>
<app-services></app-services>
<app-booking></app-booking>
</div>