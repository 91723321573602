<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Long Term & Short Term Lease</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p> Our impeccable service, high-quality drivers, and a kid-friendly staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
                <p>We have longstanding partnerships with multiple personal transport providers. Choose from many different vehicle options, from a classic and comfortable sedan to take you to your meeting, to a luxury Limo Bus.</p>
                <p>Wherever you need to go, whatever you need to do, and whomever you need to do it with, we will get you there comfortably, safely, and in style. Whatever your needs, we have a vehicle to suit them.</p>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/contract.png"/>
            </div>
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->