import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-on-rent-transport',
  templateUrl: './on-rent-transport.component.html',
  styleUrls: ['./on-rent-transport.component.css']
})
export class OnRentTransportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
