 <!--== Page Title Area Start ==-->
 <section id="page-title-area" class="section-padding overlay">
    <div class="container">
        <div class="row">
            <!-- Page Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Our Clients</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Page Title End -->
        </div>
    </div>
</section>
<!--== Page Title Area End ==-->
<!--== Client Page Area Start ==-->
<div class="client-page section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>It is our mission to provide you with the best service in the industry, from the best vehicles to the best chauffeurs with always your safety and satisfaction as our top priority.
Our clientele includes tours and charters, school groups, sports team, religious and recreational groups, corporate executives, tour companies, inbound international tourists and conventions. 
We offer a variety of services that are designed to meet your every need. Providing safe equipment with outstanding customer service and reliability is our difference!
We are the trusted transportation bus provider of these clients:
</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/img/clients/client-1.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-2.png" alt="client-logo" />
             </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-3.png" alt="client-logo" />
            </div>       
            <div class="col-md-3">
                <img src="assets/img/clients/client-4.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-5.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-6.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-7.png" alt="client-logo" />
            </div>
            <div class="col-md-3">
                <img src="assets/img/clients/client-8.png" alt="client-logo" />
            </div>
        </div>
    </div>
</div>
<!--== Client Page Area End ==-->