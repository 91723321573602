  <!--== Footer Area Start ==-->
  <section id="footer-area">
    <!-- Footer Widget Start -->
    <div class="footer-widget-area">
        <div class="container">
            <div class="row">
                <!-- Single Footer Widget Start -->
                <div class="col-lg-4 col-md-6">
                    <div class="single-footer-widget">
                        <h2>About Us</h2>
                        <div class="widget-body">
                            <img src="assets/img/logo.png" alt="JSOFT" width="100px">
                            <p>To provide you with the best service in the industry, from the best vehicles to the best chauffeurs with always your safety and satisfaction as our top priority.</p>
                        </div>
                    </div>
                </div>
                <!-- Single Footer Widget End -->

                <!-- Single Footer Widget Start -->
                <div class="col-lg-4 col-md-6">
                    <div class="single-footer-widget">
                        <h2>Our Location</h2>
                        <div class="widget-body">
                            <p><i class="fa fa-map-marker" style="color:#cca349"></i> 
                                    Albathaa Transportation Trading & Services<br/>
                                    P.O.Box. 82588,<br/>
                                    Doha, Qatar<br/>
                                    C.R.NO: 146702
                                </p>
                        </div>
                    </div>
                </div>
                <!-- Single Footer Widget End -->

                <!-- Single Footer Widget Start -->
                <div class="col-lg-4 col-md-6">
                    <div class="single-footer-widget">
                        <h2>get touch</h2>
                        <div class="widget-body">
                            <ul class="get-touch">
                                <li><i class="fa fa-mobile" style="color:#cca349"></i> +974 44373725 | +974 77116373 <br/> +974 33424131</li>
                                <li><i class="fa fa-envelope" style="color:#cca349" ></i>info@albathaaqatar.com <br/> albathaaqatar@gmail.com </li>
                            </ul> 
                        </div>
                    </div>
                </div>
                <!-- Single Footer Widget End -->
            </div>
        </div>
    </div>
    <!-- Footer Widget End -->

    <!-- Footer Bottom Start -->
    <div class="footer-bottom-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom End -->
</section>
<!--== Footer Area End ==-->