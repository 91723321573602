<section id="service-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Hotel Transportation</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Section Title End -->
        </div>
        <!-- Service Content Start -->
        <div class="row">
            <!-- Single Service Start -->
            <div class="col-lg-7">
                <p>We would love to be your independent group travel partner. We understand the pressure of handling your guest’s timely arrival and departure. Our buses can accommodate almost any group, regardless of the size. Our team of experts has experience working with both large and small event planners, offering many different charter bus rentals to make sure your event is a success.</p>
                <p>Professional and prompt, our drivers are trained to ensure your utmost safety and comfort. Our fleet of vehicles ranges from classic and comfortable to stylish and sophisticated. Whether your clients are travelling alone or with a group, we have the perfect bus or car for you. You can organize your own group and charter a bus with us.</p>
                <p>We even provide Airport transportation. Our main goal is to provide transportation to and from airports in a friendly and professional environment. When you're travelling with us, we are committed that you travel stress-free. Sit back and relax, and let us take care of the rest.</p>
                <p>The safety and comfort of our passengers is paramount and so all our vehicles are fitted with First Aid kits, water coolers, multiple emergency exits and auto engine fire suppression system. All seats are equipped with seat belts and individual AC controls. </p>
                <p>Our impeccable service, high-quality drivers and highly professional staff, are just a few of the advantages that you can expect to enjoy when you book one of our charter buses.</p>
            </div>
            <!-- Single Service End -->
            
            <!-- Single Service Start -->
            <div class="col-lg-5 text-center">
                <img src="assets/img/services/hotel.png"/>
            </div>
            <!-- Single Service End -->   
        </div>    
    </div>
</section>
<!--== Services Area End ==-->