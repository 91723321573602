 <!--== Page Title Area Start ==-->
 <section id="page-title-area" class="section-padding overlay">
    <div class="container">
        <div class="row">
            <!-- Page Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Career</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Page Title End -->
        </div>
    </div>
</section>
<!--== Page Title Area End ==-->
<div class="contact-page-wrao section-padding">
    <div class="container">
        <div class="row"><h2>Current vacancies</h2>
            <div class="col-md-12">
                <!--<div id="ff-compose"></div>-->
                
                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Office Admin
                        </button>
                      </h5>
                    </div>
                
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        Supports company operations by maintaining office systems and supervising staff.
Maintains office services by organizing office operations and procedures, preparing payroll, controlling correspondence, designing filing systems, reviewing and approving supply requisitions, and assigning and monitoring clerical functions.
Provides historical reference by defining procedures for retention, protection, retrieval, transfer, and disposal of records.
Designs and implements office policies by establishing standards and procedures, measuring results against standards, and making necessary adjustments. 
                <p>Apply on info@albathaaqatar.com</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>


