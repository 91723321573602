 <!--== Page Title Area Start ==-->
 <section id="page-title-area" class="section-padding overlay">
    <div class="container">
        <div class="row">
            <!-- Page Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Contact Us</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                </div>
            </div>
            <!-- Page Title End -->
        </div>
    </div>
</section>
<!--== Page Title Area End ==-->

<!--== Contact Page Area Start ==-->
<div class="contact-page-wrao section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 m-auto">
                <div class="contact-form">
                    <form  (submit)="sendEmail($event)">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <p><b>Contact Details</b></p>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="user_name" placeholder="Full Name" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="email" name="user_email" placeholder="Email Address" required>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="contact" placeholder="Contact Number" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="company" placeholder="Company Name" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <p><b>Transportation Requirements</b></p>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="passengers" placeholder="No. of Passengers">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="hrs" placeholder="Hours of Operation">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="routes" placeholder="No. of Routes">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="contact-input">
                                    <input type="text" name="period" placeholder="Contract Period">
                                </div>
                            </div>
                        </div>
                        <div class="message-input">
                            <textarea  name="message" cols="30" rows="10" placeholder="Description of Needs"></textarea>
                        </div>

                        <div class="input-submit">
                            <input type="submit" value="Get A Quote" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--== Contact Page Area End ==-->

<!--== Map Area Start ==-->
<div class="maparea">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.623154731771!2d51.47250841501064!3d25.249614683871883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDE0JzU4LjYiTiA1McKwMjgnMjguOSJF!5e0!3m2!1sen!2sqa!4v1602926179859!5m2!1sen!2sqa" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<!--== Map Area End ==-->