<!--== Header Area Start ==-->
<header id="header-area" class="fixed-top">
    <!--== Header Top Start ==-->
    <div id="header-top" class="d-none d-xl-block">
        <div class="container">
            <div class="row">
                <!--== Single HeaderTop Start ==-->
                <div class="col-lg-3 text-left">
                    <i class="fa fa-envelope"></i> info@albathaaqatar.com
                </div>
                <!--== Single HeaderTop End ==-->

                <!--== Single HeaderTop Start ==-->
                <div class="col-lg-3 text-center">
                    <i class="fa fa-mobile"></i> +974 44373725
                </div>
                <!--== Single HeaderTop End ==-->

                <!--== Single HeaderTop Start ==-->
                <div class="col-lg-3 text-center">
                    <i class="fa fa-clock-o"></i> Mon-Fri 09.00 - 17.00
                </div>
                <!--== Single HeaderTop End ==-->

                <!--== Social Icons Start ==-->
                <div class="col-lg-3 text-right">
                    <div class="header-social-icons">
                        <a href="#"><i class="fa fa-facebook"></i></a>
                        <a href="#"><i class="fa fa-instagram"></i></a>
                        <a href="#"><i class="fa fa-linkedin"></i></a>
                    </div>
                </div>
                <!--== Social Icons End ==-->
            </div>
        </div>
    </div>
    <!--== Header Top End ==-->

    <!--== Header Bottom Start ==-->
    <div id="header-bottom">
        <div class="container">
            <div class="row">
                <!--== Logo Start ==-->
                <div class="col-lg-4">
                    <a href="index.html" class="logo navbar-brand">
                        <img src="assets/img/logo1.png" alt="JSOFT">
                    </a>
                </div>
                <!--== Logo End ==-->

                <!--== Main Menu Start ==-->
                <div class="col-lg-8 d-none d-xl-block">
                    <nav class="mainmenu alignright">
                        <ul>
                            <li><a routerLink="/" routerLinkActive="active">Home</a></li>
                            <li><a routerLink="about" routerLinkActive="active" class="dropdown">About</a>
                                <ul>
                                    <li><a routerLink="clients" routerLinkActive="active">Our Clients</a></li>
                                    <li><a routerLink="partners" routerLinkActive="active">Our Partners</a></li>
                                    <li><a routerLink="suppliers" routerLinkActive="active">Our Suppliers</a></li>
                                </ul>
                            </li>
                            <li><a routerLink="services" routerLinkActive="active">Services</a></li>
                            <li><a routerLink="booking" routerLinkActive="active">Our Vehicles</a></li>
                            <li><a routerLink="career" routerLinkActive="active">Career</a></li>
                            <li><a routerLink="contact" routerLinkActive="active">Contact Us</a></li>
                        </ul>
                    </nav>
                </div>
                <!--== Main Menu End ==-->
            </div>
        </div>
    </div>
    <!--== Header Bottom End ==-->
</header>
<!--== Header Area End ==-->
<router-outlet></router-outlet>