
<!--== Articles Area Start ==-->
 <section id="tips-article-area" class="section-padding">
    <div class="container">
        <div class="row">
            <!-- Section Title Start -->
            <div class="col-lg-12">
                <div class="section-title  text-center">
                    <h2>Blogs</h2>
                    <span class="title-line"><i class="fa fa-bus"></i></span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
            </div>
            <!-- Section Title End -->
        </div>

        <!-- Articles Content Wrap Start -->
        <div class="row">
            <!-- Single Articles Start -->
            <div class="col-lg-12">
                <article class="single-article">
                    <div class="row">
                        <!-- Articles Thumbnail Start -->
                        <div class="col-lg-5">
                            <div class="article-thumb">
                                <img src="assets/img/article/arti-thumb-1.jpg" alt="JSOFT">
                            </div>
                        </div>
                        <!-- Articles Thumbnail End -->

                        <!-- Articles Content Start -->
                        <div class="col-lg-7">
                            <div class="display-table">
                                <div class="display-table-cell">
                                    <div class="article-body">
                                        <h3><a href="article-details.html">Wliquam sit amet urna eullam</a></h3>
                                        <div class="article-meta">
                                            <a href="#" class="author">By :: <span>Admin</span></a>
                                            <a href="#" class="commnet">Comments :: <span>10</span></a>
                                        </div>

                                        <div class="article-date">25 <span class="month">jan</span></div>

                                        <p>Wlam aiber vestibulum fringilla oremedad ipsum dolor sit amet consectetur adipisicing elit sed doned eiusmod tempored incididunt ut labore et dolore magna aliquaa enimd ad minim veniad.</p>

                                        <a href="article-details.html" class="readmore-btn">Read More <i class="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Articles Content End -->
                    </div>
                </article>
            </div>
            <!-- Single Articles End -->

            <!-- Single Articles Start -->
            <div class="col-lg-12">
                <article class="single-article middle">
                    <div class="row">

                        <!-- Articles Thumbnail Start -->
                        <div class="col-lg-5 d-xl-none">
                            <div class="article-thumb">
                                <img src="assets/img/article/arti-thumb-2.jpg" alt="JSOFT">
                            </div>
                        </div>
                        <!-- Articles Thumbnail End -->

                        <!-- Articles Content Start -->
                        <div class="col-lg-7">
                            <div class="display-table">
                                <div class="display-table-cell">
                                    <div class="article-body">
                                        <h3><a href="article-details.html">fringilla oremedad ipsum dolor sit</a></h3>
                                        <div class="article-meta">
                                            <a href="#" class="author">By :: <span>Admin</span></a>
                                            <a href="#" class="commnet">Comments :: <span>10</span></a>
                                        </div>

                                        <div class="article-date">14<span class="month">feb</span></div>

                                        <p>Wlam aiber vestibulum fringilla oremedad ipsum dolor sit amet consectetur adipisicing elit sed doned eiusmod tempored incididunt ut labore et dolore magna aliquaa enimd ad minim veniad.</p>

                                        <a href="article-details.html" class="readmore-btn">Read More <i class="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Articles Content End -->

                        <!-- Articles Thumbnail Start -->
                        <div class="col-lg-5 d-none d-xl-block">
                            <div class="article-thumb">
                                <img src="assets/img/article/arti-thumb-2.jpg" alt="JSOFT">
                            </div>
                        </div>
                        <!-- Articles Thumbnail End -->
                    </div>
                </article>
            </div>
            <!-- Single Articles End -->

            <!-- Single Articles Start -->
            <div class="col-lg-12">
                <article class="single-article">
                    <div class="row">
                        <!-- Articles Thumbnail Start -->
                        <div class="col-lg-5">
                            <div class="article-thumb">
                                <img src="assets/img/article/arti-thumb-3.jpg" alt="JSOFT">
                            </div>
                        </div>
                        <!-- Articles Thumbnail End -->

                        <!-- Articles Content Start -->
                        <div class="col-lg-7">
                            <div class="display-table">
                                <div class="display-table-cell">
                                    <div class="article-body">
                                        <h3><a href="article-details.html">Tempored incididunt ut labore</a></h3>
                                        <div class="article-meta">
                                            <a href="#" class="author">By :: <span>Admin</span></a>
                                            <a href="#" class="commnet">Comments :: <span>10</span></a>
                                        </div>

                                        <div class="article-date">17 <span class="month">feb</span></div>

                                        <p>Wlam aiber vestibulum fringilla oremedad ipsum dolor sit amet consectetur adipisicing elit sed doned eiusmod tempored incididunt ut labore et dolore magna aliquaa enimd ad minim veniad.</p>

                                        <a href="article-details.html" class="readmore-btn">Read More <i class="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Articles Content End -->
                    </div>
                </article>
            </div>
            <!-- Single Articles End -->
        </div>
        <!-- Articles Content Wrap End -->
    </div>
</section>
<!--== Articles Area End ==-->
