import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industrial-transport',
  templateUrl: './industrial-transport.component.html',
  styleUrls: ['./industrial-transport.component.css']
})
export class IndustrialTransportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
