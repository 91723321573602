<div class="row highlights-title">
    <!-- Section Title Start -->
    <div class="col-lg-12">
        <div class="section-title  text-center">
            <h2>Our Highlights</h2>
            <span class="title-line"><i class="fa fa-bus"></i></span>
        </div>
    </div>
    <!-- Section Title End -->
</div>
<div class="row highlights-container">
    <!-- Single We Do Start -->
    <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-1">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>Break Down Support 24×7</h3>
                        <p>A team of technicians and drivers with the Break Down service vehicle will be always alert to attend all recovery needs on 24 x 7 basis.</p>
                    </div>
                </div>
            </div>
        </div>
        <h3>Break Down Support 24×7</h3>
    </div>
    <!-- Single We Do End -->

    <!-- Single We Do Start -->
      <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-2">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>24×7 Customer Support</h3>
                        <p>Our customer support team is available on 24×7 basis to assist /help you in all your needs.</p>
                    </div>
                </div>
            </div>
        </div>
        <h3>24×7 Customer Support</h3>
    </div>
    <!-- Single We Do End -->

    <!-- Single We Do Start -->
    <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-3">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>School Bus Hostess</h3>
                        <p>CPR & First Aid trained female hostesses are there in all our school buses to keep your child safe back home.</p>
                    </div>
                </div>
            </div>
        </div>
        <h3>School Bus Hostess</h3>
    </div>
    <!-- Single We Do End -->

    <!-- Single We Do Start -->
    <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-4">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>WIFI/AVLS</h3>
                        <p>GPS tracking in each vehicle for safety and security. High speed WIFI connectivity for passenger convenience (as per customer request only).</p>
                    </div>
                </div>
            </div>
        </div>
        <h3>WIFI/AVLS</h3>
    </div>
    <!-- Single We Do End -->

      <!-- Single We Do Start -->
      <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-5">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>Vehicle Inspection</h3>
                        <p>Prior to start of all operations our Safety team, Quality Team & Security team will review the performance & condition of our vehicle to ensure the smooth and safe operations.</p>
                    </div>
                </div>
            </div>
        </div>
       <h3>Vehicle Inspection</h3>
    </div>
    <!-- Single We Do End -->

      <!-- Single We Do Start -->
      <div class="col-lg-4 col-md-4">
        <div class="single-we-do we-do-bg-6">
            <div class="we-do-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <h3>Vehicle Hygiene</h3>
                        <p>Vehicle cleanliness and hygiene is importent for us before starting of each day service.</p>
                    </div>
                </div>
            </div>
        </div>
        <h3>Vehicle Hygiene</h3>
    </div>
    <!-- Single We Do End -->
</div>
